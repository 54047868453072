.about-page {

  h1 {
    font-size: 53px;
    font-family: 'Coolvetica';
    color: #ffd700;
    font-weight: 400;
    margin-top: 0;
    position: relative;
    margin-bottom: 20px;
    left: -10px;

  }

  p {
    font-size: 13px;
    color: #fff;
    font-family: sans-serif;
    font-weight: 300;
    max-width: fit-content;
    animation: pulse 1s;

    &:nth-of-type(1) {
      animation-delay: 1.1s;
    }

    &:nth-of-type(2) {
      animation-delay: 1.2s;
    }

    &:nth-of-type(3) {
      animation-delay: 1.3s;
    }
  }
  h2 {
    font-size: 16px;
    font-family: 'Coolvetica';
    color: #acacc9;
    font-weight: 400;
    margin-top: 0;
    position: relative;
    margin-bottom: 35px;
    left: -10px;
  }

  .text-zone {
    position: absolute;
    left: 10%;
    top: 40%;
    transform: translateY(-50%);
    width: 35%;
    max-height: 90%;
  }

  .text-animate-hover {
    &:hover {
      color: #4476ff;
    }
  }

  .image-flip {
    left: 50%;
  }
  .bg {
    position: absolute;
    top: 17%;
    left: 60%;
    width: 45vw;
    height: 80vh;
    background-size: cover;
    background-position: center;
    will-change: opacity;
    max-width: 400px;
    max-height: 500px;
  }
  .flex {
    display: flex;
    align-items: center;
  }

  .flex.fill {
    height: 100%;
  }

  .flex.center {
    justify-content: center;
  }

}

