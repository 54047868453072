.experience-page {
  padding-left: 100px;
  padding-right: 50px;
  width: calc(100% - 150px);
  position: initial;
  height: 100%;
  overflow: auto;

  h1.page-title {
    margin-left: 100px;
    margin-top: 100px;
  }

  .experience-container {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    padding-bottom: 100px;
  }

  .experience-box {
    position: relative;
    flex: 1 1 20%;
    height: auto;
    overflow: hidden;
    border-radius: 10px;
    max-width: calc(25% - 10px);
    min-height: 200px;

    .experience-image {
      position: absolute;
      z-index: 2;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .experience-content {
      position: absolute;
      width: 100%;
      z-index: 3;
      padding: 8px 10px;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      background: linear-gradient(
                      180deg,
                      rgba(0, 0, 0, 0.2) 0,
                      rgba(0, 0, 0, 1)
      );
      bottom: -70px;

    }

    .experience-title {
      margin-bottom: 0;
      margin-top: 0;
      color: #fff;
      font-size: 24px;
      font-weight: 500;
      line-height: 24px;
    }

    .experience-description {
      font-size: 11px;
      margin-bottom: 4px;
      color: #fff;
      font-weight: 700;
      height: auto;
      display: none;
    }


    .experience-btn {
      margin-top: 30px;
      margin-bottom: 10px;
      padding: 0 23px;
      height: 40px;
      line-height: 34px;
      border: 2px solid #1100ff;
      border-radius: 4px;
      font-size: 14px;
      color: #fff;
      background: transparent;
      text-transform: uppercase;
      font-weight: 700;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      cursor: pointer;


    }

    .experience-btn:hover {
      transform: translateY(-3px);
      background: #0022ff;

    }

    &:after {
      content: "";
      background: linear-gradient(180deg, #0002ff, #000);
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      opacity: 0;

    }

    &:hover:after {
      opacity: 0.85;



    }

    &:hover .experience-content {
      bottom: 0;
      background: transparent;
      .experience-description {
        display: block;
      }



    }


  }
}